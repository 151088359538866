

@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.contact3 {
  font-family: "Montserrat", sans-serif;
  color: #3e4555;
  font-weight: 300;
}

.contact3 h1,
.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color: #3e4555;
}

.contact3 .font-weight-medium {
  font-weight: 500;
}

.contact3 .card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.contact3 .btn-danger-gradiant {
  background: #4aa7ee;
  background: -webkit-linear-gradient(legacy-direction(to right), #4aa7ee 0%, #4aa7ee 100%);
  background: -webkit-gradient(linear, left top, right top, from(#4aa7ee), to(#4aa7ee));
  background: -webkit-linear-gradient(left, #4aa7ee 0%, #4aa7ee 100%);
  background: -o-linear-gradient(left, #4aa7ee 0%, #4aa7ee 100%);
  background: linear-gradient(to right, #4aa7ee 0%, #4aa7ee 100%);
}

.contact3 .btn-danger-gradiant:hover {
  background: #4aa7ee;
  background: -webkit-linear-gradient(legacy-direction(to right), #4aa7ee 0%, #4aa7ee 100%);
  background: -webkit-gradient(linear, left top, right top, from(#4aa7ee), to(#4aa7ee));
  background: -webkit-linear-gradient(left, #4aa7ee 0%, #4aa7ee 100%);
  background: -o-linear-gradient(left, #4aa7ee 0%, #4aa7ee 100%);
  background: linear-gradient(to right, #4aa7ee 0%, #4aa7ee 100%);
}